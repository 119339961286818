<template>
  <base-page
      :title="$t('platformDataManage.title')"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="formList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.fieldFindList"
        :columns="columns"
        :defaultParams="defaultParams"
        v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime) }}
      </template>
      <!-- 规则 -->
      <template v-slot:relationApi="{ row }">
        {{  row.externalApi.name }}
      </template>
      <template v-slot:dataForm="{ row }">
        {{$t('dataFormEnum.' + row.dataForm)}}
      </template>
    </table-list>
    <detail v-if="showDetail"
            :tableRow="tableRow"
            @closePage="closePage"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
import {fieldDel} from "@/api";
export default {
  components: {
    Detail
  },

  data() {
    return {
      formData: {},
      defaultParams: {
        scope: 'CHANNEL_LINE',
        inputType: 'API_COLLECT',
        companyUid: this.$store.getters.company.uid,
      },
      tableRow: null,
      showDetail: false,
      columns: [
        {
          label: this.$t('platformDataManage.name'),
          prop: 'name',
        },
        {
          label: this.$t('platformDataManage.mark'),
          prop: 'mark',
        },
        {
          label: this.$t('platformDataManage.relationApi'),
          prop: 'relationApi',
        },
        {
          label: this.$t('platformDataManage.fieldName'),
          prop: 'dataName',
        },
        {
          label: this.$t('platformDataManage.relationField'),
          prop: 'dataField',
        },
        {
          label: this.$t('platformDataManage.fieldType'),
          prop: 'dataForm',
        },
        {
          label: this.$t('platformDataManage.fieldUnit'),
          prop: 'dataUnit',
        },
        {
          label: this.$t('platformDataManage.fieldUnitDes'),
          prop: 'dataUnitDes',
        },
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
          width: 200,
        },
      ],
    }
  },
  methods: {
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }

      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.formList.searchList(formData, isActiveQuery)
    },
    deleteMethod() {
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.fieldDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
